













































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Global } from '@/store';
import moment from 'moment-timezone';
import DatePicker from './CustomDatePicker.js';
import InfoDialog from '../InfoDialog.vue';
import cloneDeep from 'lodash/cloneDeep';

// @ts-ignore
@Component({ 
    components: {
        DatePicker,
        InfoDialog
    
}})
export default class Calendar extends Vue {
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;

    @Prop() setSchedule;
    @Prop() activeCurve;
    @Prop() schedule;
    @Prop() updateScheduleCurves;
    @Prop() curves_map;
    @Prop() schedule_curves_map;

    show_dates = true;
    scheduleDays = [];
    calendar_months = [];
    days_in_week = [
        {
            en: 'Sunday',
            he: 'א',
            value: 0
        },
        {
            en: 'Monday',
            he: 'ב׳',
            value: 1
        },
        {
            en: 'Tuesday',
            he: 'ג׳',
            value: 2
        },
        {
            en: 'Wednesday',
            he: 'ד׳',
            value: 3
        },
        {
            en: 'Thursday',
            he: 'ה׳',
            value: 4
        },
        {
            en: 'Friday',
            he: 'ו׳',
            value: 5
        },
        {
            en: 'Saturday',
            he: 'ש׳',
            value: 6
        }
    ];

    monthNames = [
        {en: 'January', he: 'ינואר'},
        {en: 'February', he: 'פברואר'},
        {en: 'March', he: 'מרץ'},
        {en: 'April', he: 'אפריל'},
        {en: 'May', he: 'מאי'},
        {en: 'June', he: 'יוני'},
        {en: 'July', he: 'יולי'},
        {en: 'August', he: 'אוגוסט'},
        {en: 'September', he: 'ספטמבר'},
        {en: 'October', he: 'אוקטובר'},
        {en: 'November', he: 'נובמבר'},
        {en: 'December', he: 'דצמבר'}
    ];

    mounted(){
        this.setMonths();
        if (this.schedule.curves){
            this.setSelectedScheduleDays();
        }
    }

    setMonths() {
        const months = [];
        for (let i = 0; i < 3; i++) {
            months.push([
                moment(moment()
                .add(i, 'M'))
                .tz(this.projectTimezone)
                .toISOString()
                .substr(0, 7)
            ]);
        }
        this.calendar_months = months;
    }

    setSelectedScheduleDays(){
        const dates_with_curve = this.schedule.curves.map((day_curve) => day_curve.date);
        this.calendar_months = this.calendar_months.map((month) => {
            const dates_in_month = dates_with_curve.filter((date) => date.includes(month[0]));
            return month.concat(dates_in_month);
        });
    }

    @Watch('schedule_curves_map')
    updateSelectedScheduleDays(){
        const dates_with_curve = this.schedule.curves.map((day_curve) => day_curve.date);
        this.calendar_months = this.calendar_months.map((month) => {
            const month_and_year = month[0];
            const dates_in_month = month.slice(1).filter((date) => dates_with_curve.includes(date));
            return [month_and_year].concat(dates_in_month);
        });
    }

    getThreeMonths(action, units) {
        this.calendar_months = this.calendar_months.map((month) => {
            const date = moment(month[0])
                [action](units, 'M')
                .tz(this.projectTimezone)
                .toISOString()
                .substr(0, 7);
            return [date];
        });
        this.setSelectedScheduleDays(); // to update selected days again
    }

    handleDayClicked(day_in_week) {
        const index = this.scheduleDays.indexOf(day_in_week.value);
        const start = moment(this.calendar_months[0][0]);
        const end = moment(this.calendar_months[2][0]);

        const selectedDates = this.getCalendarSelectedDays(start, end.add(1, 'M'), day_in_week.value);

        if (index !== -1) {
            this.scheduleDays.splice(index, 1);
            this.removeDatesFromScheduler(selectedDates);
        } else {
            this.scheduleDays.push(day_in_week.value);
            this.addDatesToShceduler(selectedDates);
        }
    }

    removeDatesFromScheduler(selectedDates){
        this.calendar_months.forEach((month) => {
            selectedDates.forEach((selected_date) => {
                if (selected_date.includes(month[0])) {
                    const selected_index = month.indexOf(selected_date);
                    if (selected_index > -1) {
                        const i = this.schedule.curves.map((curve) => curve.date).indexOf(selected_date);
                        const new_curves = this.schedule.curves.slice(0, i).concat(this.schedule.curves.slice(i + 1));
                        this.updateScheduleCurves(new_curves);
                        month.splice(selected_index, 1);
                    }
                }
            });
        });
    }

    addDatesToShceduler(selectedDates){
        this.calendar_months.forEach((month) => {
            selectedDates.forEach((selected_date) => {
                if (selected_date.includes(month[0])) {
                    const selected_index = month.indexOf(selected_date);
                    if (selected_index === -1) {
                        const new_curve_date = {
                            id: this.activeCurve.id,
                            date: selected_date
                        };
                        const new_curves = this.schedule.curves.concat([new_curve_date]);
                        this.updateScheduleCurves(new_curves);
                        month.push(selected_date);
                    } else {
                        const i = this.schedule.curves.map((v) => v.date).indexOf(selected_date);
                        const new_curves = this.schedule.curves.slice(0, i).concat([{
                            id: this.activeCurve.id,
                            date: selected_date
                        }]).concat(this.schedule.curves.slice(i + 1));
                        this.updateScheduleCurves(new_curves);
                    }
                }
            });
        });
    }

    getCalendarSelectedDays(start, end, day_number) {
        const selected_days = [];
        const date = start.clone().day(day_number); // set specific day for the first selected month
        while (date.isBefore(end)) {
            selected_days.push(date.format('YYYY-MM-DD'));
            date.add(7, 'days');
        }
        return selected_days;
    }

    dateFormate(date) {
        if (!date) return;
     
        const [year, month, day] = date.split('-');
        const month_text = this.lang === 'he' ? this.monthNames[Number(month) - 1].he : this.monthNames[Number(month) - 1].en;
        return `${month_text} ${year}`;
    }

    getDateColor(date) {
        const curve = this.schedule.curves.find((curve) => curve.date === date);
        const curve_id = curve && curve.id || '';
 
        if (curve_id && this.curves_map.has(curve_id)) return this.curves_map.get(curve_id).color;
    }

    @Watch('curves_map')
    updateData(){
        this.show_dates = false;
        this.$nextTick(() => this.show_dates = true);
    }

    countDeletedDates(date, month_index) {
        const date_index = this.schedule.curves.map((v) => v.date).indexOf(date);
        if (date_index !== -1) {
            if (this.schedule.curves[date_index].id === this.activeCurve.id || !this.activeCurve.id) {
                const new_curves = this.schedule.curves.slice(0, date_index).concat(this.schedule.curves.slice(date_index + 1));
                this.updateScheduleCurves(new_curves);
                const ind = this.calendar_months[month_index].indexOf(date);
                this.calendar_months[month_index].splice(ind, 1);
            } else {
                const new_curve = {...this.schedule.curves[date_index]};
                new_curve.id = this.activeCurve.id;
                const new_curves = this.schedule.curves.slice(0, date_index).concat([new_curve]).concat(this.schedule.curves.slice(date_index + 1));
                this.updateScheduleCurves(new_curves);
            }
        } else if (this.activeCurve.id) {
            const new_curve_date = {
                id: this.activeCurve.id,
                date
            };
            const new_curves = cloneDeep(this.schedule.curves).concat([new_curve_date]);
            this.updateScheduleCurves(new_curves);
            this.calendar_months[month_index].push(date);
        }
    }
}

